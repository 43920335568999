import {
  signalStore, withState, withMethods, getState,
  patchState, withComputed, withHooks
} from '@ngrx/signals';
import { computed, effect } from '@angular/core';
import { IAuth } from 'features/user/api/api-tokens';

type AuthState = {
  auth: IAuth | null;
};

const auth: IAuth = JSON.parse(localStorage.getItem('__auth') ?? 'null');
if (auth) {
  auth.expires = new Date(auth.expires);
  auth.refreshExpires = new Date(auth.refreshExpires);
}

const initialState: AuthState = {
  auth: auth
};

export const AuthStore = signalStore(
  withState(initialState),
  withComputed((store) => ({
    accessToken: computed(() => {
      console.log("getting accessToken");
      const auth = store.auth ? store.auth() : null;
      if (!auth) {
        return null;
      }
      return auth.freelyToken;
    }),
    refreshToken: computed(() => {
      const auth = store.auth ? store.auth() : null;
      if (!auth) {
        return null;
      }
      return auth.refreshToken;
    }),
    isTokenValid: computed(() => {
      const auth = store.auth ? store.auth() : null;
      if (!auth) {
        return false;
      }

      const now = new Date().getTime();
      const expires = auth.expires.getTime();
      return now < expires;
    }),

    isRefreshTokenValid: computed(() => {
      const auth = store.auth ? store.auth() : null;
      if (!auth) {
        return false;
      }

      const now = new Date().getTime();
      const expires = auth.refreshExpires.getTime();
      return now < expires;
    }),
  })),
  withComputed((store) => ({
    isLoggedIn: computed(() => {
      return store.isTokenValid();
    }),
  })),
  withMethods((store) => {
    return ({
      login(auth: IAuth): void {
        auth.expires = new Date(auth.expires);
        auth.refreshExpires = new Date(auth.refreshExpires);
        patchState(store, {
          auth: auth
        });

      },
      logout(): void {
        patchState(store, {
          auth: null
        });
      },
    });
  }),
  withHooks({
    onInit(store) {
      effect(() => {
        // 👇 The effect is re-executed on state change.
        const state = getState(store);
        localStorage.setItem('__auth', JSON.stringify(store.auth()));
      });

    },
  })
);
