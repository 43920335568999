import { IApiRequest, IApiResponse, IApiResponseError, IApiResponseSuccess } from './api-types';


export class ApiRequest implements IApiRequest {

}
export class ApiResponse implements IApiResponse {

}
export class ApiResponseError implements IApiResponseError {
  constructor(
    public message: string,
    public code: number,
    public httpStatus?: number,
    public data?: any

  ) { }
}
export class ApiResponseSuccess implements IApiResponseSuccess {

}
