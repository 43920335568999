import { Inject, inject, Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { map, Observable, of } from 'rxjs';
import { IApiService } from 'core/api/services/api.interface';
import { AuthStore } from '../store/auth.store';
import { IAuth, UserAuthApiService } from 'features/user/api/api-tokens';

@Injectable()
export class UserAuthService {
  readonly userStore = inject(AuthStore);
  constructor(
    @Inject(UserAuthApiService) protected apiService: IApiService
  ) { }

  isAuthenticated() {
    if (this.userStore.isLoggedIn()) {
      return of(true);
    }

    // try to refresh token
    return this.refreshToken();
  }

  refreshToken(): Observable<boolean> {
    return of(false);
    if (!this.userStore.isRefreshTokenValid()) {
      return of(false);
    }

    return this.apiService.post<IAuth>('userRefreshToken', {
      headers: {
        authorization: 'Bearer ' + this.userStore.refreshToken()
      }
    }).pipe(
      map(response => {
        this.userStore.login(response)
        return true;
      }),
      catchError((e) => {
        return of(false);
      })
    );
  }

  loginByUsernameAndPassword(username: string, password: string) {
    return this.apiService.post<IAuth>('userLogin', {
      body: {
        username: username,
        password: password
      }
    }).pipe(
      tap(response => this.userStore.login(response))
    );
  }

  loginByGoogleToken(token: string) {
    return this.apiService.post<IAuth>('userLogin', {
      body: {
        googleToken: token
      }
    }).pipe(
      tap(response => this.userStore.login(response))
    );
  }

  logout() {

    return this.apiService.post<IAuth>('userLogout').pipe(
      tap(response => this.userStore.logout())
    );
  }
}
